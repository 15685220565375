<template>
    <div>
        <img class="example-headImg" src="../assets/image/selected/08-3banner.png"/>
        <div class="head-explain">
            <img class="head-img-logo" src="../assets/image/selected/08-3iEvent.png" style="width:11.5rem;height: 5.3rem;"/>
            <div class="head-explain-title">学术会议管理</div>
            <div class="head-explain-content">IQVIA iEvent 是专为医药和器械行业设计的</div>
            <div class="head-explain-content" style="margin-top:-3rem">学术会议管理一站式数字化平台</div>
        </div>
        <div class="container-1">
            <div class="saled-box1">
                <div class="title1">
                    IQVIA iEvent 是专为医药和器械行业设计的学术会议管理一站式数字化平台. IQVIA iEvent完整支持各类学术会议、市场推广、专业服务等类型的活动。<br/>
                    同时提供完整合规控制、以及财务运营处理功能，为企业业务发展保驾护航。
                </div>
            </div>
        </div>
        <div class="container-2" style="height:90rem">
            <div class="saled-box2">
                <div class="container">
                    <div class="card">
                        <img src="../assets/image/selected/08-3手机端.png"/>
                        <div class="title" style="margin-top:2.5rem">手机端操作设计</div>
                    </div>
                    <div class="card">
                        <img src="../assets/image/selected/08-3微信体系.png"/>
                        <div class="title" style="margin-top:2.5rem">微信体系接驳</div>
                    </div>
                    <div class="card">
                        <img src="../assets/image/selected/08-3全面的合规.png"/>
                        <div class="title" style="margin-top:2.5rem">全面的合规和财务管控</div>
                    </div>
                </div>
                <img class="saled-box2-img" src="../assets/image/selected/08-3中图.png"/>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.saled-box1{
    width: 139.7rem;
    height: 8.8rem;
    margin: auto;
    margin-top: 4.4rem;
    margin-bottom: 2rem;
    .title1{
        width: 139.7rem;
        height: 8.8rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #333333;
        line-height: 3rem;
        margin-top: 2rem;
        
    }
}
.saled-box2{
    width: 86rem;
    margin: auto;
    .container{
        width: 86rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card{
            width: 26rem;
            display: flex;
            margin-top: 5rem;
            margin-bottom: 5.6rem;
            img{
                width: 7rem;
                height: 7rem;
                float: left;
            }
            .title{
                margin-left: 2.1rem;
                width: 18rem;
                font-size: 1.4rem;
                font-family: 'CN_Normal';
                text-align: left;
                margin-top: 1.5rem;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }
    .saled-box2-img{
        width: 73.5rem;
        height: 53.1rem;
    }
}
</style>